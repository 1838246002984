import React from 'react'
import Layout from '../../components/layouts/courseLandingLayout'
import styled from 'styled-components';
import { Container, Col, Row } from 'react-bootstrap';
import logoBlack from '../../assets/images/parsity-logo-black.png';

const LinksContainer = styled(Container)`
  margin-top: 100px;

  p {
    margin-top: 20px;
  }

  .white-logo {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
  }
`

const PSButtom = styled.a`
  margin: 0 auto;
  background: #fff;
  border: 2px solid #000;
  padding: 15px 20px;
  display: block;
  text-align: center;
  color: #000;
  text-decoration: none;
  box-shadow: #000 -10px 8px 0px;
  letter-spacing: 2px;
  font-size: 20px;
  min-width: 150px;
  text-align: center;
  font-family: 'roboto-mono';
  max-width: 400px;
  margin-top: 30px;
  &:hover {
    background: var(--yellow);
    box-shadow: none;
    color: var(--black);
  }
`

const Links = () => {
  return (
    <Layout>
      <LinksContainer fluid>
        <Row>
          <Col md={12}>
            <img className='white-logo' src={logoBlack} alt=''></img>
            <PSButtom href='https://us02web.zoom.us/j/88497249116' className="ps-btn-main">Join the Upcoming Live Q&A</PSButtom>
            <p className='text-center'><em>Let us know if the link has expired!</em></p>
          </Col>
        </Row>
      </LinksContainer>
    </Layout>
  )
}

export default Links
